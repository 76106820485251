.rankingsAppBar-enter {
  height: 0px;
}

.rankingsAppBar-exit {
  height: 36px;
}

.rankingsAppBar-exit-done {
  display: none;
}

.rankingsAppBar-enter.rankingsAppBar-enter-active {
  height: 36px;
  transition: height 0.3s ease;
}

.rankingsAppBar-exit.rankingsAppBar-exit-active {
  height: 0px;
  transition: height 0.3s ease;
}
